/* ./src/index.css */
@tailwind base;
@tailwind components;

.section {
    @apply w-full p-6 bg-blue-200;
}

.section-title{
    @apply w-48 mx-auto pt-6 border-b-2 border-blue-500 text-center text-2xl text-blue-700;    
}

.section-sub-title{
    @apply p-2 text-center text-lg text-gray-700;
}

.card{
    @apply w-48 m-4 py-2 bg-white shadow-lg;
}

.card-image{
    @apply w-24 h-24 mx-auto rounded-full;
}
.card-title{
    @apply mx-2 mt-2 text-center text-lg text-gray-700 font-semibold;
}
.card-sub-title{
    @apply text-center text-gray-600;
}

.card-icons{
    @apply flex justify-center items-center mt-2;
}

.card-icon{
    @apply mx-1 text-blue-500 cursor-pointer;
}

.card-icon:hover{
    @apply text-blue-700;
}

@tailwind utilities;

.-skewx-20{
    transform: skewX(-20deg);
}